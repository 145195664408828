import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import CommissionPercentageInput from './ComissionPercentageInput.js';
import generalSettingsService from '../../../api/generalSettingsService';
import payPlansService from '../../../api/payPlansService.js';
import { useConfirmationModal } from '../../../hooks/useConfirmation.js';

function CommissionForm({ commission, onSubmit, onCancel, payPlanId, showAlert }) {
  const [formData, setFormData] = useState({
    name: '',
    depth: 1,
    percentageByLevel: '{}',
    cycleId: 'one-time', 
    numberOfPeriods: null,
    selfPayPercentage: 0,
    isGrouped: false,
    proOption: null,
    initialDelay: null,
    delayType: 'IMMEDIATE'
  });
  const [cycles, setCycles] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const { openModal } = useConfirmationModal();
  const delayTypes = [
    { value: 'IMMEDIATE', label: 'Immediate Payment' },
    { value: 'DAY', label: 'Days' },
    { value: 'WEEK', label: 'Weeks' },
    { value: 'MONTH', label: 'Months' }
  ];
  

  useEffect(() => {
    fetchCycles();
  }, []);

  useEffect(() => {
    if (commission) {
      console.log('Commission data:', commission);
      if (commission) {
        setFormData({
          id: commission.id,
          name: commission.name || '',
          depth: commission.depth || 1,
          percentageByLevel: typeof commission.percentageByLevel === 'string' 
            ? commission.percentageByLevel 
            : JSON.stringify(commission.percentageByLevel || {}),
          cycleId: commission.cycleId ? commission.cycleId.toString() : 'one-time', 
          numberOfPeriods: commission.numberOfPeriods || null,
          selfPayPercentage: commission.selfPayPercentage || 0,
          isGrouped: commission.isGrouped || false,
          // Add default value for delayType when null
          delayType: commission.delayType || 'IMMEDIATE',
          // Add default value for initialDelay
          initialDelay: commission.initialDelay || null,
          proOption: commission.proOption ? {
            percentageByLevel: typeof commission.proOption.percentageByLevel === 'string'
              ? commission.proOption.percentageByLevel
              : JSON.stringify(commission.proOption.percentageByLevel || {}),
            infiniteDepthPercentage: commission.proOption.infiniteDepthPercentage || 0
          } : null
        });
    
        if (commission.cycleId) {
          const cycle = cycles.find(c => c.id === commission.cycleId);
          setSelectedCycle(cycle);
        } else {
          setSelectedCycle(null);
        }
      }

      // Set selectedCycle based on cycleId
      if (commission.cycleId) {
        const cycle = cycles.find(c => c.id === commission.cycleId);
        setSelectedCycle(cycle);
      } else {
        setSelectedCycle(null);
      }
    }
  }, [commission, cycles]);

  useEffect(() => {
    if (formData.cycleId && formData.cycleId !== 'one-time') {
      const cycle = cycles.find(c => c.id.toString() === formData.cycleId);
      setSelectedCycle(cycle);
    } else {
      setSelectedCycle(null);
    }
  }, [formData.cycleId, cycles]);

  const fetchCycles = async () => {
    try {
      const fetchedCycles = await generalSettingsService.getCycles();
      setCycles(fetchedCycles);
    } catch (error) {
      console.error("Error fetching cycles:", error);
      showAlert('error', 'Failed to fetch payment cycles');
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prevData => {
      const newData = {
        ...prevData,
        [name]: type === 'number' ? Math.max(1, parseFloat(value)) : value,
      };

      
      return newData;
    });;
    if (name === 'cycleId') {
      if (value === 'one-time') {
        setSelectedCycle(null);
        setFormData(prev => ({ ...prev, numberOfPeriods: null }));
      } else {
        const cycle = cycles.find(c => c.id.toString() === value);
        setSelectedCycle(cycle);
      }
    }
  };

  const handlePercentageChange = (percentages) => {
    setFormData(prevData => ({
      ...prevData,
      percentageByLevel: percentages
    }));
  };

  const handleProOptionPercentageChange = (percentages) => {
    setFormData(prevData => ({
      ...prevData,
      proOption: {
        ...prevData.proOption,
        percentageByLevel: percentages
      }
    }));
  };

  const handleProOptionChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      proOption: {
        ...prevData.proOption,
        [name]: type === 'number' ? parseFloat(value) : value
      }
    }));
  };

  const handleIsGroupedChange = (e) => {
    const { checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      isGrouped: checked
    }));
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      cycleId: formData.cycleId === 'one-time' ? null : parseInt(formData.cycleId),
      percentageByLevel: JSON.parse(formData.percentageByLevel),
      proOption: formData.proOption ? {
        ...formData.proOption,
        percentageByLevel: JSON.parse(formData.proOption.percentageByLevel)
      } : null,
      initialDelay: formData.delayType === 'IMMEDIATE' ? null : formData.initialDelay,
      delayType: formData.delayType === 'IMMEDIATE' ? null : formData.delayType
    };

   

    openModal('Confirm Submission', 'Are you sure you want to submit this commission?', async () => {
      try {
        let updatedCommission;
        if (commission) {
          updatedCommission = await payPlansService.updateCommission(payPlanId, commission.id, submissionData);
        } else {
          updatedCommission = await payPlansService.createCommission(payPlanId, submissionData);
        }
        showAlert('success', `Commission ${commission ? 'updated' : 'created'} successfully`);
        onSubmit(updatedCommission, commission ? true : false);
      } catch (error) {
        showAlert('error', `Error ${commission ? 'updating' : 'creating'} commission. Please try again.`);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">
          {commission ? 'Edit Commission' : 'Create New Commission'}
        </h2>
        <button type="button" onClick={onCancel} className="text-gray-400 hover:text-gray-600">
          <X size={24} />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Depth</label>
          <input
            type="number"
            name="depth"
            value={formData.depth}
            onChange={handleChange}
            min="1"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Commission Percentages by Level</label>
          <CommissionPercentageInput
            depth={formData.depth}
            initialPercentages={formData.percentageByLevel}
            onChange={handlePercentageChange}
          />
        </div>
        <div>
          <label htmlFor="cycleId" className="block text-sm font-medium text-gray-700 mb-1">Payment Cycle</label>
          <select
            id="cycleId"
            name="cycleId"
            value={formData.cycleId}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          >
            <option value="">Select a cycle</option>
            <option value="one-time">One-Time Payment</option>
            {cycles.map(cycle => (
              <option key={cycle.id} value={cycle.id.toString()}>
                {cycle.name} ({cycle.periodValue} {cycle.periodType.toLowerCase()}(s))
              </option>
            ))}
          </select>
        </div>
        {selectedCycle && (
          <div>
            <label htmlFor="numberOfPeriods" className="block text-sm font-medium text-gray-700 mb-1">Number of Periods</label>
            <input
              type="number"
              id="numberOfPeriods"
              name="numberOfPeriods"
              value={formData.numberOfPeriods || ''}
              onChange={handleChange}
              min="1"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
        )}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Self-Pay Percentage</label>
          <input
            type="number"
            name="selfPayPercentage"
            value={formData.selfPayPercentage}
            onChange={handleChange}
            step="0.01"
            min="0"
            max="100"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="flex items-start space-x-4">
  <div className="flex-1">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      Payment Delay
    </label>
    <select
      name="delayType"
      value={formData.delayType || 'IMMEDIATE'}
      onChange={handleChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
    >
      {delayTypes.map(type => (
        <option key={type.value} value={type.value}>
          {type.label}
        </option>
      ))}
    </select>
  </div>

  {formData.delayType !== 'IMMEDIATE' && (
    <div className="flex-1">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Delay Duration (in {formData.delayType.toLowerCase()}s)
      </label>
      <input
        type="number"
        name="initialDelay"
        value={formData.initialDelay || ''}
        onChange={handleChange}
        min="1"
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        required
      />
    </div>
  )}
</div>
      <div className="mt-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="isGrouped"
            checked={formData.isGrouped}
            onChange={handleIsGroupedChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">Make this commission selectable by users</span>
        </label>
      </div>

      <div className="mt-6">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={!!formData.proOption}
            onChange={() => setFormData(prevData => ({
              ...prevData,
              proOption: prevData.proOption ? null : {
                percentageByLevel: '{}',
                infiniteDepthPercentage: 0
              }
            }))}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-sm font-medium text-gray-700">Pro Option</span>
        </label>
      </div>

      {formData.proOption && (
        <div className="mt-6 p-4 border-t border-gray-200">
          <h4 className="font-semibold text-lg mb-4">Pro Option Details</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Pro Option Commission Percentages by Level</label>
              <CommissionPercentageInput
                depth={formData.depth}
                initialPercentages={formData.proOption.percentageByLevel}
                onChange={handleProOptionPercentageChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Infinite Depth Percentage</label>
              <input
                type="number"
                name="infiniteDepthPercentage"
                value={formData.proOption.infiniteDepthPercentage}
                onChange={handleProOptionChange}
                step="0.01"
                min="0"
                max="100"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 flex justify-end space-x-3">
        <button type="button" onClick={onCancel} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Cancel
        </button>
        <button type="submit" className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          {commission ? 'Update Commission' : 'Add Commission'}
        </button>
      </div>
    </form>
  );
}

export default CommissionForm;