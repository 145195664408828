import React, { useState, useEffect } from 'react';

const CommissionPercentageInput = ({ depth, initialPercentages, onChange }) => {
  const [percentages, setPercentages] = useState({});

  useEffect(() => {
    let parsedPercentages = {};
    if (typeof initialPercentages === 'string') {
      try {
        parsedPercentages = JSON.parse(initialPercentages);
      } catch (error) {
        console.error('Error parsing initialPercentages:', error);
      }
    } else if (typeof initialPercentages === 'object') {
      parsedPercentages = initialPercentages;
    }

    const sanitizedPercentages = {};
    for (let i = 1; i <= depth; i++) {
      sanitizedPercentages[i] = parseFloat(parsedPercentages[i]) || 0;
    }
    setPercentages(sanitizedPercentages);
  }, [depth, initialPercentages]);

  const handlePercentageChange = (level, value) => {
    const newPercentages = { ...percentages, [level]: parseFloat(value) || 0 };
    setPercentages(newPercentages);
    onChange(JSON.stringify(newPercentages));
  };

  const renderInputBoxes = () => {
    if (depth === 0) {
      return <p className="text-gray-500 italic">No percentage inputs required for depth 0.</p>;
    }

    const boxes = [];
    for (let i = 1; i <= depth; i++) {
      boxes.push(
        <div key={i} className="flex flex-col items-center m-1">
          <label className="text-xs mb-1">Level {i}</label>
          <input
            type="number"
            value={percentages[i] || 0}
            onChange={(e) => handlePercentageChange(i, e.target.value)}
            className="w-16 h-8 text-center border rounded"
            min="0"
            max="100"
            step="0.001"
          />
        </div>
      );
    }
    return boxes;
  };

  return (
    <div className="flex flex-wrap justify-start items-start max-w-4xl">
      {renderInputBoxes()}
    </div>
  );
};

export default CommissionPercentageInput;