import authService from '../auth';
import { logger } from '../../utils/logger';

export const errorInterceptor = async (error) => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    const currentPath = window.location.pathname;
    const isAdminRoute = currentPath.startsWith('/admin');
    
    logger.warn('Authentication error', { 
      status: error.response?.status,
      path: currentPath,
      isAdminRoute
    });

    await authService.logout(isAdminRoute);
  }
  
  return Promise.reject(error);
};