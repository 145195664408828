import axios from 'axios';
import { authInterceptor } from '../middleware/authInterceptor';
import { errorInterceptor } from '../middleware/errorInterceptor';
import { API_URL, DEFAULT_TIMEOUT } from '../../config/constants';

const createAxiosInstance = (config = {}) => {
  const instance = axios.create({
    baseURL: API_URL,
    timeout: DEFAULT_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
    },
    ...config
  });

  instance.interceptors.request.use(authInterceptor, error => Promise.reject(error));
  instance.interceptors.response.use(
    response => response,
    errorInterceptor
  );

  return instance;
};

export const axiosInstance = createAxiosInstance();