import {axiosInstance} from './config/axiosConfig';

const memberService = {

  activateMember: async (memberId, activationData) => {
    try {
      const payload = {
        memberId: parseInt(memberId),
        planId: parseInt(activationData.planId),
        amount: parseFloat(activationData.amount),
        date: activationData.activationDate,
        payTypeId: activationData.payType
      };

      const response = await axiosInstance.post('/activation/activate-member', payload, 
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getMembers: async (filters = {}, page = 1, limit = 10) => {
    try {
      const { email, username, planStatus, id } = filters;
      let queryParams = new URLSearchParams({
        page: page.toString(),
        limit: limit.toString()
      });

      if (email) queryParams.append('email', email);
      if (username) queryParams.append('username', username);
      if (planStatus) queryParams.append('planStatus', planStatus);
      if (id) queryParams.append('id', id );

      const response = await axiosInstance.get(`/members?${queryParams.toString()}`,
       { useAdminToken: true} );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

    // Get current user's profile (for logged-in user)
    getCurrentUserProfile: async () => {
      try {
        const response = await axiosInstance.get('/members/current-user', { useMemberToken: true });
        return response.data;
      } catch (error) {
        console.error('Error fetching current user profile:', error);
        throw error;
      }
    },

    getMemberByUsername: async (username) => {
      try {
        const response = await axiosInstance.get(`/members/by-username/${username}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching member by username:', error);
        throw error;
      }
    },

    createTestMembers: async (hostUsername, baseName, numberOfReferrals) => {
      try {
        const response = await axiosInstance.post('/members/create-test-members', 
          { hostUsername, baseName, numberOfReferrals },
          { useAdminToken: true });
        return response.data;
      } catch (error) {
        console.error('Error creating test members:', error);
        throw error;
      }
    },



  updatePassword: async (currentPassword, newPassword) => {
    try {
      const response = await axiosInstance.put('/members/update-password', {
        currentPassword,
        newPassword
      });
      return response.data;
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  },

  // Update a specific member's personal info (for admin use)
  adminUpdateUserProfile: async (memberId, personalInfo) => {
    try {
      const response = await axiosInstance.put(`/members/mmbr-update-profile/${memberId}`, 
        { personalInfo }, { useAdminToken: true}  );
      return response.data;
    } catch (error) {
      console.error('Error updating member personal info:', error);
      throw error;
    }
  },

  updatePersonalInfo: async (personalInfo) => {
    try {
      const response = await axiosInstance.put('/members/update-profile', 
        { personalInfo }, { useMemberToken: true} );
      return response.data;
    } catch (error) {
      console.error('Error updating personal info:', error);
      throw error;
    }
  },

  deleteMember: async (memberId) => {
    try {
      const response = await axiosInstance.delete(`/members/${memberId}`,
        { useAdminToken: true });
      return response.data;
    } catch (error) {
      console.error('Error deleting member:', error);
      throw error;
    }
  },


};

export default memberService;