import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import authService from '../../api/auth';
import payPlansService from '../../api/payPlansService';
import generalSettingsService from '../../api/generalSettingsService';
import { createPersonalInfo, validatePersonalInfo } from '../../models/personalInfo';
import { useAlert } from '../../hooks/useAlert';

function Registration() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    payPlanId: '',
    selectedCommissionId: '',
  });
  
  const [error, setError] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [payPlans, setPayPlans] = useState([]);
  const [groupedCommissions, setGroupedCommissions] = useState([]);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const {showAlert} = useAlert();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch general settings first
        const settingsResponse = await generalSettingsService.getSettings();
        setSettings(settingsResponse.data);

        // Get referral from URL
        const searchParams = new URLSearchParams(location.search);
        const ref = searchParams.get('ref');
        if (ref) {
          setReferredBy(ref);
        }

        // Only fetch pay plans if we should show registration
        if (settingsResponse.data.enableRegistration && 
            (!settingsResponse.data.mandatoryReferral || ref)) {
          await fetchPublicPayPlans();
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load registration settings. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [location]);

  const fetchPublicPayPlans = async () => {
    try {
      const plans = await payPlansService.getPublicPayPlans();
      setPayPlans(plans);
    } catch (error) {
      console.error('Error fetching pay plans:', error);
      setError('Failed to fetch pay plans. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'payPlanId') {
      fetchGroupedCommissions(value);
    }
  };

  const fetchGroupedCommissions = async (payPlanId) => {
    try {
      const commissions = await payPlansService.getGroupedCommissions(payPlanId);
      setGroupedCommissions(commissions);
      if (commissions.length > 0) {
        setFormData(prev => ({ ...prev, selectedCommissionId: commissions[0].id.toString() }));
      } else {
        setFormData(prev => ({ ...prev, selectedCommissionId: '' }));
      }
    } catch (error) {
      console.error('Error fetching commissions:', error);
      setError('Failed to fetch commissions. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    
    try {
      const personalInfo = createPersonalInfo({
        firstName: formData.firstName,
        lastName: formData.lastName,
      });

      const personalInfoErrors = validatePersonalInfo(personalInfo);
      if (personalInfoErrors) {
        setError('Invalid personal information');
        console.error(personalInfoErrors);
        return;
      }
      
      const registrationData = {
        ...formData,
        personalInfo,
        referredBy
      };
      
      const response = await authService.register(registrationData);
      console.log('Registration successful', response.data);
      await authService.login(formData.username, formData.password);
      showAlert ('success',  'Welcome OnBoard!' );
      
      navigate('/member/dashboard');
    } catch (error) {
      setError('Registration failed. Please try again.');
      console.error('Error during registration:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  // Show message if registration is disabled
  if (!settings?.enableRegistration) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="px-8 py-6 text-center bg-white shadow-lg rounded-lg w-full max-w-md">
          <h3 className="text-2xl font-bold text-red-600 mb-4">Registration Unavailable</h3>
          <p className="text-gray-600 mb-4">
            Registration is currently disabled. Please contact the administrator for more information.
          </p>
          <Link to="/login" className="text-blue-600 hover:underline">
            Return to Login
          </Link>
        </div>
      </div>
    );
  }

  // Show message if referral is required but missing
  if (settings?.mandatoryReferral && !referredBy) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="px-8 py-6 text-center bg-white shadow-lg rounded-lg w-full max-w-md">
          <h3 className="text-2xl font-bold text-yellow-600 mb-4">Referral Required</h3>
          <p className="text-gray-600 mb-4">
            A referral is required to register. Please use a valid referral link to continue.
          </p>
          <Link to="/login" className="text-blue-600 hover:underline">
            Return to Login
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-lg w-full max-w-4xl">
        <h3 className="text-2xl font-bold text-center">Register for an account</h3>
        {error && <p className="text-red-500 text-sm italic mb-4">{error}</p>}
        {referredBy && (
          <p className="text-green-500 text-sm mb-4">
            You were referred by: {referredBy}
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mt-4">
            <div className="mt-4">
              <label className="block" htmlFor="payPlanId">Select Pay Plan</label>
              <select
                name="payPlanId"
                value={formData.payPlanId}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                required
              >
                <option value="">Select a pay plan</option>
                {payPlans.map(plan => (
                  <option key={plan.id} value={plan.id}>{plan.name}</option>
                ))}
              </select>
            </div>

            {groupedCommissions.length > 0 && (
              <div className="mt-4">
                <label className="block" htmlFor="selectedCommissionId">Select Commission</label>
                <select
                  name="selectedCommissionId"
                  value={formData.selectedCommissionId}
                  onChange={handleChange}
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                  required
                >
                  {groupedCommissions.map(commission => (
                    <option key={commission.id} value={commission.id}>{commission.name}</option>
                  ))}
                </select>
              </div>
            )}

            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="username">Username</label>
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-sm font-medium text-gray-700">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="flex items-center justify-between mt-6">
              <button 
                type="submit"
                className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Register
              </button>
              <Link to="/login" className="text-sm text-blue-600 hover:underline">
                Already have an account? Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Registration;
