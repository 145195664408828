import React from 'react';

function MemberTickets() {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Support Tickets</h2>
      <div className="mb-4">
        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Create New Ticket
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-left">Ticket ID</th>
              <th className="py-2 px-4 border-b text-left">Subject</th>
              <th className="py-2 px-4 border-b text-left">Status</th>
              <th className="py-2 px-4 border-b text-left">Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">001</td>
              <td className="py-2 px-4 border-b">Question about referrals</td>
              <td className="py-2 px-4 border-b">Open</td>
              <td className="py-2 px-4 border-b">2023-05-20</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MemberTickets;