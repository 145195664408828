import React, { useState, useEffect } from 'react';
import { Search, Edit, Trash2, Power, UserPlus, LogIn } from 'lucide-react';

import memberService from '../../../api/memberService';
import authService from '../../../api/auth';
import MemberModal from './MemberModal';  
import { useAlert } from '../../../hooks/useAlert';

import {useConfirmationModal} from '../../../hooks/useConfirmation';
import CreateTestMembersModal from './CreateTestMembersModal';
import CreateMemberModal from './CreateMemberModal';

import UserCreationButtons from './UserCreationButtons';
import { createPersonalInfo, validatePersonalInfo } from '../../../models/personalInfo';
import ActivateMemberModal from './ActivateMemberModal';


const ManageMembers = () => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ email: '', username: '', planStatus: '' });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();
  const [isCreateMemberModalOpen, setIsCreateMemberModalOpen] = useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const [isTestMembersModalOpen, setIsTestMembersModalOpen] = useState(false);


  useEffect(() => {
    fetchMembers();
    const isEnv = process.env.REACT_APP_DEV_ENV;
    if (!isEnv) {
      
      let stop = 1;

    }
  }, [pagination.pageNumber]);


  const fetchMembers = async () => {
    setIsLoading(true);
    try {
      const data = await memberService.getMembers(filters, pagination.pageNumber, pagination.pageSize);
      setMembers(data.members);
      setPagination(data.metadata);
    } catch (err) {
      setError(err.message || 'An error occurred while fetching members');
    } finally {
      setIsLoading(false);
    }
  };

  const handleActivationClick = (member) => {

    setSelectedMember(member);
    setIsActivationModalOpen(true);
    const stop = 1;
    
  };

  const handleActivateMember = async (formData) => {
    try {
      await memberService.activateMember(selectedMember.id, formData);
      showAlert('success', 'Member activated successfully');
     
    } catch (error) {
      showAlert('error', 'Failed to activate member');
      console.error('Error activating member:', error);
    }
    setIsActivationModalOpen(false);
    setSelectedMember(null); 
    fetchMembers();
  };


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
    fetchMembers();
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const handleEditMember = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (member) => {
    openModal(
      'Confirm Deletion',
      `Are you sure you want to delete the member ${member.username}? This action cannot be undone.`,
      async () => {
        try {
          await memberService.deleteMember(member.id);
          showAlert('success', 'Member deleted successfully');
          fetchMembers();
        } catch (error) {
          showAlert('error', 'Failed to delete member');
          console.error('Error deleting member:', error);
        }
      }
    );
  };

  const handleCloseModal = () => {
    setSelectedMember(null);
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleUpdateMember = () => {
    fetchMembers();
    handleCloseModal();
  };

  const handleCreateMember = async (memberData) => {
    try {
      await authService.register(memberData);
      showAlert('success', 'Member created successfully');
      fetchMembers(); // Refresh the member list
    } catch (error) {
      showAlert('error', 'Failed to create member');
      console.error('Error creating member:', error);
    }
    setIsCreateMemberModalOpen(false);
  };

  const handleCreateTestMembers = async (data) => {
    try {
      const result = await memberService.createTestMembers(data.hostUsername, data.baseName, data.numberOfReferrals);
      showAlert('success', `Successfully created ${result.createdMembers.length} test members`);
      fetchMembers(); // Refresh the member list
    } catch (error) {
      showAlert('error', 'Failed to create test members');
      console.error('Error creating test members:', error);
    }
    setIsTestMembersModalOpen(false);
  };

  const handleLoginAsUser = async (member) => {
    try {
      await authService.adminLoginAsUser(member.username);
      showAlert('success', `Opening new window to login as ${member.username}`);
    } catch (error) {
      showAlert('error', 'Failed to login as user');
      console.error('Error logging in as user:', error);
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4 bg-red-100 rounded-lg">{error}</div>;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-2 bg-gradient-to-r from-blue-500 to-indigo-600">
        <h2 className="text-2xl font-bold text-white">Manage Members</h2>
      </div>
              <UserCreationButtons
          onCreateUser={() => setIsCreateMemberModalOpen(true)}
          onCreateTestUsers={() => setIsTestMembersModalOpen(true)}
          showTestUsersButton={process.env.REACT_APP_DEV_ENV === 'true'}
        />
      
      <form onSubmit={handleSearch} className="p-6 bg-gray-50 border-b border-gray-200">
        <div className="flex flex-wrap items-center -mx-3 mb-4">
          <div className="w-full md:w-1/3 px-3 mb-4 md:mb-0">
            <input
              type="text"
              name="email"
              placeholder="Filter by email"
              value={filters.email}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-4 md:mb-0">
            <input
              type="text"
              name="username"
              placeholder="Filter by username"
              value={filters.username}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="w-full md:w-1/4 px-3 mb-4 md:mb-0">
            <select
              name="planStatus"
              value={filters.planStatus}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Plan Statuses</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="PAYMENT_REPORTED">Payment Reported</option>
            </select>
          </div>
          <div className="w-full md:w-auto px-3">
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full transition duration-300 ease-in-out flex items-center justify-center">
              <Search size={20} />
            </button>
          </div>
        </div>
      </form>

      <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Username</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Plan Status</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Activation Date</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {members.map((member) => (
            <tr key={member.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <button onClick={() => handleLoginAsUser(member)} className="text-blue-600 hover:text-blue-900">
                  {member.username}
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  member.planStatus === 'ACTIVE' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {member.planStatus || 'N/A'}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {member.activatedAt? new Date(member.activatedAt).toLocaleDateString(
                  'en-US',
                  { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }
                ) : 'Not Activated'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
          
                <button 
                  onClick={() => handleEditMember(member)} 
                  className="text-indigo-600 hover:text-indigo-900 mr-2"
                >
                  <Edit size={18} />
                </button>
                <button 
                  onClick={() => handleDeleteClick(member)} 
                  className="text-red-600 hover:text-red-900 mr-2"
                >
                  <Trash2 size={18} />
                </button>
                <button 
                  onClick={() => handleActivationClick(member)}
                  disabled={member.planStatus === 'ACTIVE'}
                  className={`text-gray-600 hover:text-gray-900 ${member.planStatus === 'ACTIVE' ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <Power size={18} />
                </button>
                <button 
                  onClick={() => handleLoginAsUser(member)}
                  className="text-blue-600 hover:text-blue-900"
                  title="Login as this user"
                >
                  <LogIn size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={() => handlePageChange(pagination.pageNumber - 1)}
            disabled={!pagination.hasPreviousPage}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChange(pagination.pageNumber + 1)}
            disabled={!pagination.hasNextPage}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{(pagination.pageNumber - 1) * pagination.pageSize + 1}</span> to <span className="font-medium">{Math.min(pagination.pageNumber * pagination.pageSize, pagination.totalCount)}</span> of{' '}
              <span className="font-medium">{pagination.totalCount}</span> results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => handlePageChange(pagination.pageNumber - 1)}
                disabled={!pagination.hasPreviousPage}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(pagination.pageNumber + 1)}
                disabled={!pagination.hasNextPage}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>

      <MemberModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        member={selectedMember}
        onUpdate={handleUpdateMember}
      />

    <CreateTestMembersModal
        isOpen={isTestMembersModalOpen}
        onClose={() => setIsTestMembersModalOpen(false)}
        onSubmit={handleCreateTestMembers}
      />

    <CreateMemberModal
        isOpen={isCreateMemberModalOpen}
        onClose={() => setIsCreateMemberModalOpen(false)}
        onCreateMember={handleCreateMember}
      />
      <ActivateMemberModal
        isOpen={isActivationModalOpen}
        onClose={() => setIsActivationModalOpen(false)}
        onActivateMember={handleActivateMember}
        selectedMember={selectedMember}
      />
    </div>
  );
};

export default ManageMembers;