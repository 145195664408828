import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation , useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MemberLogin from './components/members/MemberLogin';
import ProtectedRoute from './components/protectedRoutes';
import AdminLogin from './components/admin/AdminLogin';
import Registration from './components/members/Registration';
import AdminPage from './components/admin/AdminPage.js';
import AdminDashboard from './components/admin/AdminDashboard';
import ManageMembers from './components/admin/manage_members/ManageMembers';
import Transactions from './components/admin/Transactions';
import Trees from './components/admin/trees/Trees';
import Tickets from './components/admin/Tickets';
import GeneralSettings from './components/admin/general_settings/GeneralSettings';
import Payplans from './components/admin/payplans/Payplans';
import MemberPage from './components/members/MemberPage';
import MemberDashboard from './components/members/MemberDashboard';
import MemberTransactions from './components/members/MemberTransactions';
import Referrals from './components/members/Referrals';
import MemberTrees from './components/members/MemberTrees';
import MemberTickets from './components/members/MemberTickets';
import Profile from './components/members/Profile';
import { AlertProvider } from './contexts/AlertContext';
import authService from './api/auth.js';
import {clearAdmin, clearMember} from './store/authSlice';
import { setLoading } from './store/authSlice';
import RockNRollLoader from './components/common/RockNRollLoader.js';
import {ConfirmationModalProvider} from "./contexts/ConfirmationContext";
import {logger} from "./utils/logger";


function AppContent() {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const { loading } = useSelector(state => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to determine the login route
  const determineLoginRoute = (path) => {
    return path.startsWith('/admin') ? '/admin-login' : '/login';
  };

  // If you have a clearAuthState action, import it from your auth slice
  // Otherwise, you can handle the cleanup directly:
  const handleAuthCleanup = (isAdmin) => {
    if (isAdmin) {
      dispatch(clearAdmin()); // Make sure to import clearAdmin from your auth slice
    } else {
      dispatch(clearMember()); // Make sure to import clearMember from your auth slice
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      dispatch(setLoading(true));
      logger.log('Initializing auth');
      
      try {
        const authResult = await authService.initializeAuth(location.pathname);
        
        if (authResult?.tokenNeedsRefresh) {
          // If you have refresh token functionality:
          // await authService.refreshToken();
          // If not, you might want to redirect to login
          navigate(determineLoginRoute(location.pathname));
          return;
        }
        
        setIsInitialized(true);
      } catch (error) {
        logger.error('Auth initialization error:', error);
        
        if (error.isTokenExpired) {
          // Handle expired token
          const isAdmin = location.pathname.startsWith('/admin');
          handleAuthCleanup(isAdmin);
          navigate(determineLoginRoute(location.pathname));
        }
      } finally {
        dispatch(setLoading(false));
      }
    };
    
    initAuth();
  }, [dispatch, location.pathname, navigate]);

  if (loading || !isInitialized) {
    return <RockNRollLoader />;
  }

  // Determine the appropriate login route
  const loginRoute = location.pathname.startsWith('/admin') ? '/admin-login' : '/login';

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={loginRoute} />} />
      <Route path="/login" element={<MemberLogin />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/admin" element={
        <ProtectedRoute adminOnly={true}>
          <AdminPage />
        </ProtectedRoute>
      }>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="manage-members" element={<ManageMembers />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="trees" element={<Trees />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="general-settings" element={<GeneralSettings />} />
        <Route path="payplans" element={<Payplans />} />
      </Route>
      <Route path="/member" element={
        <ProtectedRoute>
          <MemberPage />
        </ProtectedRoute>
      }>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<MemberDashboard />} />
        <Route path="transactions" element={<MemberTransactions />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="tree" element={<MemberTrees />} />
        <Route path="tickets" element={<MemberTickets />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <ConfirmationModalProvider>
    <AlertProvider>
      <Router>
        <div className="min-h-screen bg-gray-100">
          <AppContent />
        </div>
      </Router>
    </AlertProvider>
    </ConfirmationModalProvider>
  );
}

export default App;