// personalInfo.js

const personalInfoSchema = {
  firstName: '',
  lastName: '',
  phone: '',
  btcAddress: '',
  usdtAddress: '',
  socials: {
    facebook: '',
    x: '',
    linkedin: '',
    instagram: '',
  },
  country: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
  },
  avatarUrl: '',
  bio: '',
};

function createPersonalInfo(initialData = {}) {
  return mergePersonalInfo(personalInfoSchema, initialData);
}

function mergePersonalInfo(currentInfo, newInfo) {
  return {
    ...JSON.parse(JSON.stringify(currentInfo)),
    ...newInfo,
    socials: {
      ...currentInfo.socials,
      ...(newInfo.socials || {}),
    },
    address: {
      ...currentInfo.address,
      ...(newInfo.address || {}),
    },
  };
}

function updatePersonalInfo(currentInfo, newInfo) {
  return mergePersonalInfo(currentInfo, newInfo);
}

function validatePersonalInfo(info) {
  const errors = {};

  if (!info.firstName) errors.firstName = 'First name is required';
  if (!info.lastName) errors.lastName = 'Last name is required';
  if (info.phone && !/^\+?[1-9]\d{1,14}$/.test(info.phone)) {
    errors.phone = 'Invalid phone number';
  }
  if (info.avatarUrl && !/^https?:\/\/.+/.test(info.avatarUrl)) {
    errors.avatarUrl = 'Invalid avatar URL';
  }

  // Add more validation as needed

  return Object.keys(errors).length > 0 ? errors : null;
}

function getFullName(info) {
  return `${info.firstName} ${info.lastName}`.trim();
}

function getFormattedAddress(info) {
  const { street, city, state, zipCode } = info.address;
  return `${street}, ${city}, ${state} ${zipCode}`.trim();
}

export {
  personalInfoSchema,
  createPersonalInfo,
  mergePersonalInfo,
  updatePersonalInfo,
  validatePersonalInfo,
  getFullName,
  getFormattedAddress,
};