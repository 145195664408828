import React, { useState } from 'react';

const CreateTestMembersModal = ({ isOpen, onClose, onSubmit }) => {
  const [hostUsername, setHostUsername] = useState('');
  const [baseName, setBaseName] = useState('');
  const [numberOfReferrals, setNumberOfReferrals] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ hostUsername, baseName, numberOfReferrals: parseInt(numberOfReferrals, 10) });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Create Test Members</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="hostUsername" className="block text-sm font-medium text-gray-700">
              Host Username
            </label>
            <input
              type="text"
              id="hostUsername"
              value={hostUsername}
              onChange={(e) => setHostUsername(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter host username"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="baseName" className="block text-sm font-medium text-gray-700">
              Base Name for Referrals
            </label>
            <input
              type="text"
              id="baseName"
              value={baseName}
              onChange={(e) => setBaseName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Enter base name"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="numberOfReferrals" className="block text-sm font-medium text-gray-700">
              Number of Referrals
            </label>
            <input
              type="number"
              id="numberOfReferrals"
              value={numberOfReferrals}
              onChange={(e) => setNumberOfReferrals(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              min="1"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTestMembersModal;