// services/reportPaymentsService.js
import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';


const ticketsService = {
    getTickets: async () => {
        try {
            const response = await axiosInstance.get('/tickets/admin', { useAdminToken: true });
            return response.data;
        } catch (error) {
            handleError(error, 'fetching tickets');
        }
    },
    getTicket: async (id) => {
        try {
            const response = await axiosInstance.get(`/tickets/${id}`);
            return response.data;
        } catch (error) {
            handleError(error, 'fetching ticket');
        }
    },

    addMessageAdmin: async (ticketId, messageData) => {
        try {
            const formData = new FormData();
            
            // Add message content
            formData.append('content', messageData.content);
            
            // Add attachment if exists
            if (messageData.attachment) {
                formData.append('attachment', messageData.attachment);
            }

            const response = await axiosInstance.post(
                `/tickets/${ticketId}/messages`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'adding message');
        }
    },
    createTicket: async (ticketData) => {
        try {
            const response = await axiosInstance.post('/tickets', ticketData);
            return response.data;
        } catch (error) {
            handleError(error, 'creating ticket');
        }
    },
    updateTicket: async (id, ticketData) => {
        try {
            const response = await axiosInstance.put(`/tickets/${id}`, ticketData);
            return response.data;
        } catch (error) {
            handleError(error, 'updating ticket');
        }
    },
    deleteTicket: async (id) => {
        try {
            const response = await axiosInstance.delete(`/tickets/${id}, `, { useAdminToken: true });
            return response.data;
        } catch (error) {
            handleError(error, 'deleting ticket');
        }
    },

    getMimeType: (filename) => {
        const ext = filename.split('.').pop().toLowerCase();
        const mimeTypes = {
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'png': 'image/png',
            'gif': 'image/gif',
            'webp': 'image/webp',
            'pdf': 'application/pdf',
            'doc': 'application/msword',
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        };
        return mimeTypes[ext] || 'application/octet-stream';
    },

    getFileUrl: (relativePath) => {
        if (!relativePath) return null;
        const cleanPath = relativePath.replace(/^uploads\//, '');
        return `/tickets/files/${cleanPath}`;
    },

    getFileData: async (fileUrl) => {
        try {
            const cleanPath = fileUrl.replace(/^uploads\//, '');
            const response = await axiosInstance({
                url: `/tickets/files/${cleanPath}`,
                method: 'GET',
                responseType: 'blob'
            });
            
            return response.data;
        } catch (error) {
            handleError(error, 'getting file data');
            throw error;
        }
    },

    

    downloadFile: async (fileUrl) => {
        try {
            const blob = await ticketsService.getFileData(fileUrl);
            const mimeType = ticketsService.getMimeType(fileUrl);
            const blobWithType = new Blob([blob], { type: mimeType });
            
            const downloadUrl = window.URL.createObjectURL(blobWithType);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileUrl.split('/').pop());
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
            
            return true;
        } catch (error) {
            handleError(error, 'downloading file');
            return false;
        }
    },

    openFile: async (fileUrl) => {
        try {
            const blob = await ticketsService.getFileData(fileUrl);
            const mimeType = ticketsService.getMimeType(fileUrl);
            const blobWithType = new Blob([blob], { type: mimeType });
            
            const blobUrl = window.URL.createObjectURL(blobWithType);
            window.open(blobUrl, '_blank');
            setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);
            
            return true;
        } catch (error) {
            handleError(error, 'opening file');
            return false;
        }
    }
};

export default ticketsService