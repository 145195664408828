import React, { useState, useEffect } from 'react';
import { DollarSign, Layers, GitBranch, RefreshCw, CreditCard } from 'lucide-react';
import {useConfirmationModal} from '../../../hooks/useConfirmation';

const initialPayPlanState = {
  name: '',
  depth: 0,
  width: 0,
  registrationFee: 0,
  renewalFee: 0,
  renewalPeriod: 0,
  reinvestmentOption: false,
  minimumWithdrawalAmount: 0
};

function PayPlanForm({ payPlan, onSubmit, isCreating, onDelete }) {
  const [formData, setFormData] = useState(initialPayPlanState);
  const { openModal } = useConfirmationModal();


  useEffect(() => {
    if (payPlan) {
      setFormData(payPlan);
    } else {
      setFormData(initialPayPlanState);
    }
  }, [payPlan]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : type === 'number' ? parseFloat(value) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    openModal(
      'Confirm Deletion',
      `Are you sure you want to delete the pay plan "${formData.name}"? This action cannot be undone.`,
      () => {
        onDelete(formData.id);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 border-b pb-2">
        {isCreating ? 'Create New Pay Plan' : 'Edit Pay Plan'}
      </h2>
      
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Plan Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="depth">
            <Layers className="inline-block mr-2" size={16} />
            Depth
          </label>
          <input
            type="number"
            id="depth"
            name="depth"
            value={formData.depth}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="width">
            <GitBranch className="inline-block mr-2" size={16} />
            Width
          </label>
          <input
            type="number"
            id="width"
            name="width"
            value={formData.width}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="registrationFee">
            <DollarSign className="inline-block mr-2" size={16} />
            Registration Fee
          </label>
          <input
            type="number"
            id="registrationFee"
            name="registrationFee"
            value={formData.registrationFee}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="renewalFee">
            <RefreshCw className="inline-block mr-2" size={16} />
            Renewal Fee
          </label>
          <input
            type="number"
            id="renewalFee"
            name="renewalFee"
            value={formData.renewalFee}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="renewalPeriod">
            <RefreshCw className="inline-block mr-2" size={16} />
            Renewal Period (months)
          </label>
          <input
            type="number"
            id="renewalPeriod"
            name="renewalPeriod"
            value={formData.renewalPeriod}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="minimumWithdrawalAmount">
            <CreditCard className="inline-block mr-2" size={16} />
            Minimum Withdrawal Amount
          </label>
          <input
            type="number"
            id="minimumWithdrawalAmount"
            name="minimumWithdrawalAmount"
            value={formData.minimumWithdrawalAmount}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
      </div>

      <div className="mb-6">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="reinvestmentOption"
            checked={formData.reinvestmentOption}
            onChange={handleInputChange}
            className="mr-2"
          />
          <span className="text-gray-700 font-bold">Reinvestment Option</span>
        </label>
      </div>

      <div className="flex items-center justify-end">
        <button 
          type="submit" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {isCreating ? 'Create Pay Plan' : 'Update Pay Plan'}
          
        </button>
        {!isCreating && (
          <button
            type="button"
            className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleDelete}
          >
            Delete
          </button>
        )}
      </div>
    </form>
  );
}

export default PayPlanForm;