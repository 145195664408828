import React, { useState } from 'react';
import treesService from '../../../api/treesService';
import memberService from '../../../api/memberService';
import MemberTree from './MemberTree';

export default function Trees() {
  const [username, setUsername] = useState('');
  const [memberId, setMemberId] = useState(null);
  const [treeData, setTreeData] = useState(null);
  const [hostLineData, setHostLineData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setTreeData(null);
    setHostLineData(null);

    try {
      // First, we need to fetch the memberId using the username
      // This is a placeholder - you'll need to implement this API call
      const memberData = await memberService.getMemberByUsername(username);
      setMemberId(memberData.id);

      // Now fetch the tree and host line data
      const [treeResponse, lineResponse] = await Promise.all([
        treesService.getMemberTree(memberData.id),
        treesService.getHostLine(memberData.id)
      ]);

      setTreeData(treeResponse);
      setHostLineData(lineResponse.hostLine);
    } catch (error) {
      setError('Error fetching data. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Member Tree</h2>
      
      <form onSubmit={fetchData} className="mb-4">
        <div className="flex items-center">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
            className="flex-grow mr-2 p-2 border rounded"
          />
          <button 
            type="submit" 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Fetch Tree'}
          </button>
        </div>
      </form>

      {error && (
        <div className="text-red-500 mb-4">{error}</div>
      )}

      {treeData && (
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-2">Downline Tree</h3>
          <MemberTree treeData={treeData} maxDepth={4} />
        </div>
      )}

      {hostLineData && (
        <div>
          <h3 className="text-xl font-semibold mb-2">Host Line</h3>
          <pre className="bg-gray-100 p-4 rounded overflow-auto">
            {JSON.stringify(hostLineData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}