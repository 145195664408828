import React, { useState } from 'react';
import { ChevronRight, ChevronDown, User } from 'lucide-react';

const getPlanStatusStyles = (planStatus) => {
  switch (planStatus?.toUpperCase()) {
    case 'ACTIVE':
      return 'text-green-600 hover:text-green-700';
    case 'INACTIVE':
      return 'text-red-600 hover:text-red-700';
    case 'PAYMENT_REPORTED':
      return 'text-yellow-600 hover:text-yellow-700';
    case 'EXPIRED':
      return 'text-gray-600 hover:text-gray-700';
    default:
      return 'text-gray-600 hover:text-gray-700';
  }
};

const TreeNode = ({ node, depth = 0, maxDepth = 4, isLast = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = node.children && node.children.length > 0;

  const toggleExpand = () => {
    if (hasChildren && depth < maxDepth) {
      setIsExpanded(!isExpanded);
    }
  };

  const statusStyle = getPlanStatusStyles(node.planStatus);

  return (
    <li className={`my-2 relative ${!isLast ? 'pb-2' : ''}`}>
      <div className="flex items-center relative">
        {depth > 0 && (
          <>
            <div className="absolute left-0 top-0 -ml-4 w-4 h-8 border-l-2 border-b-2 border-gray-300"></div>
            {!isLast && <div className="absolute left-0 top-8 -ml-4 w-0 h-full border-l-2 border-gray-300"></div>}
          </>
        )}
        <div className="flex items-center z-10 bg-white">
          <span 
            className="mr-1 cursor-pointer"
            onClick={toggleExpand}
          >
            {hasChildren && depth < maxDepth ? (
              isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />
            ) : (
              <span className="w-4"></span>
            )}
          </span>
          <User 
            size={16} 
            className={`mr-2 ${statusStyle.replace('text-', 'text-opacity-50 text-')}`} 
          />
          <span className={`font-medium ${statusStyle} cursor-pointer`}>
            {node.username}
            </span>
          <div className="ml-2 flex items-center space-x-2">
            <span className="text-gray-500 text-sm">
              ({node.children ? node.children.length : 0} Referrals)
            </span>
            <span className={`text-xs px-2 py-0.5 rounded-full ${
              node.planStatus === 'ACTIVE' ? 'bg-green-100 text-green-800' : 
              node.planStatus === 'INACTIVE' ? 'bg-red-100 text-red-800' :
              node.planStatus === 'PAYMENT_REPORTED' ? 'bg-yellow-100 text-yellow-800' :
              'bg-gray-100 text-gray-800'
            }`}>
              {node.planStatus || 'N/A'}
            </span>
          </div>
        </div>
      </div>
      {isExpanded && hasChildren && depth < maxDepth && (
        <ul className="ml-4 mt-2">
          {node.children.map((child, index) => (
            <TreeNode 
              key={child.id} 
              node={child} 
              depth={depth + 1} 
              maxDepth={maxDepth}
              isLast={index === node.children.length - 1}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const MemberTree = ({ treeData, maxDepth = 4 }) => {
  return (
    <div className="member-tree pl-4">
      <ul className="tree">
        <TreeNode node={treeData} maxDepth={maxDepth} />
      </ul>
    </div>
  );
};

export default MemberTree;