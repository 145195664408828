import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';
  
  const treesService = {
    getMemberTree: async (memberId, maxDepth = 10) => {
      try {
        const response = await axiosInstance.get(`/trees/member-tree/${memberId}`, {
          params: { maxDepth },
        });
        return response.data;
      } catch (error) {
        handleError(error, 'fetching member tree');
      }
    },
  
    getHostLine: async (memberId, maxDepth = 10) => {
      try {
        const response = await axiosInstance.get(`/trees/host-line/${memberId}`, {
          params: { maxDepth },
        });
        return response.data;
      } catch (error) {
        handleError(error, 'fetching host line');
      }
    },
  
    // You can add more tree-related functions here as needed
  };
  
  export default treesService;