// src/store/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  member: {
    currentUser: null,
    isAuthenticated: false,
    token: null,
  },
  admin: {
    currentUser: null,
    isAuthenticated: false,
    token: null,
  },
  loading: false,
  error: {
    admin: null,
    member: null,
    timestamp: null,
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMember: (state, action) => {
      state.member = {
        currentUser: action.payload.user,
        isAuthenticated: true,
        token: action.payload.token,
      };
      state.loading = false;
      state.error.member = null;
      if (!state.error.admin) {
        state.error.timestamp = null;
      }
    },
    updateMemberPlanStatus: (state, action) => {
      if (state.member.currentUser) {
        state.member.currentUser = {
          ...state.member.currentUser,
          planStatus: action.payload
        };
      }
    },
    setAdmin: (state, action) => {
      state.admin = {
        currentUser: action.payload.user,
        isAuthenticated: true,
        token: action.payload.token,
      };
      state.loading = false;
      state.error.admin = null;
      if (!state.error.member) {
        state.error.timestamp = null;
      }
    },
    clearMember: (state) => {
      state.member = initialState.member;
      state.error.member = null;
      if (!state.error.admin) {
        state.error.timestamp = null;
      }
    },
    clearAdmin: (state) => {
      state.admin = initialState.admin;
      state.error.admin = null;
      if (!state.error.member) {
        state.error.timestamp = null;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      const { type, message } = action.payload;
      state.error[type] = message;
      state.error.timestamp = new Date().toISOString();
    },
    clearError: (state, action) => {
      const type = action.payload;
      state.error[type] = null;
      if (!state.error.admin && !state.error.member) {
        state.error.timestamp = null;
      }
    },
  },
});

export const { setMember, setAdmin, clearMember, 
              clearAdmin, setLoading, setError,
              clearError, updateMemberPlanStatus } = authSlice.actions;

export const selectAdminError = (state) => state.auth.error.admin;
export const selectMemberError = (state) => state.auth.error.member;
export const selectIsLoading = (state) => state.auth.loading;
export const selectAdminAuth = (state) => state.auth.admin;
export const selectMemberAuth = (state) => state.auth.member;

export default authSlice.reducer;