import React, { useState, useEffect, useRef } from 'react';
import { Send, Image, Paperclip, Download, Maximize, X } from 'lucide-react';
import { useAlert } from '../../hooks/useAlert';
import ticketsService from '../../api/ticketsServices';

const TicketChat = ({ ticket, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const { showAlert } = useAlert();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

 

  useEffect(() => {
    fetchMessages();
    scrollToBottom();
  }, [ticket.id]);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const data = await ticketsService.getTicket(ticket.id);
      if (data.success) {
        setMessages(data.data.messages);
        scrollToBottom();
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    if (!newMessage.trim() && !attachment) return;

    try {
      const messageData = {
        content: newMessage.trim(),
        attachment: attachment
      };

      const response = await ticketsService.addMessageAdmin(ticket.id, messageData);
      
      if (response) {
        setNewMessage('');
        setAttachment(null);
        setAttachmentPreview(null);
        await fetchMessages();
        scrollToBottom();
      }
    } catch (error) {
      showAlert('error', 'Failed to send message');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file);
      
      // Create preview for images
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachmentPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setAttachmentPreview(null);
      }
    }
  };

  const clearAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
    fileInputRef.current.value = '';
  };

  const formatMessageDate = (date) => {
    return new Date(date).toLocaleString(
      'en-US',
      { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }
    );
  };

  const isImageFile = (filename) => {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(filename);
  };

  // Format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const MessageBubble = ({ message }) => {
    const isAdmin = message.isAdmin;
    const bubbleClass = isAdmin
      ? 'bg-blue-500 text-white ml-auto'
      : 'bg-gray-100 text-gray-800';
    
    const [previewUrl, setPreviewUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, []);

    useEffect(() => {
        if (message.attachment && isImageFile(message.attachment)) {
            setLoading(true);
            setError(null);
            
            const loadImage = async () => {
                try {
                    const blob = await ticketsService.getFileData(message.attachment);
                    const mimeType = ticketsService.getMimeType(message.attachment);
                    const blobWithType = new Blob([blob], { type: mimeType });
                    const url = URL.createObjectURL(blobWithType);
                    
                    setPreviewUrl(url);
                    setLoading(false);
                } catch (err) {
                    console.error('Failed to load image:', err);
                    setError('Failed to load image preview');
                    setLoading(false);
                }
            };

            loadImage();
        }
    }, [message.attachment]);

    return (
      <div className={`max-w-[70%] ${isAdmin ? 'ml-auto' : 'mr-auto'} mb-4`}>
        <div className={`rounded-lg p-3 ${bubbleClass}`}>
          <div className="flex justify-between items-start mb-1">
            <span className="font-semibold text-sm">
              {isAdmin ? 'Admin' : message.sender.username}
            </span>
            <span className="text-xs opacity-75 ml-2">
              {new Date(message.sentAt).toLocaleString()}
            </span>
          </div>
          
          {message.content && (
            <p className="whitespace-pre-wrap break-words">{message.content}</p>
          )}

          {message.attachment && (
            <div className="mt-2">
              {isImageFile(message.attachment) ? (
                <div className="relative group">
                  {loading ? (
                    <div className="flex items-center justify-center h-32 bg-gray-100 rounded">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    </div>
                  ) : error ? (
                    <div className="flex items-center justify-center h-32 bg-gray-100 rounded text-red-500">
                      {error}
                    </div>
                  ) : (
                    <div className="relative w-48 h-48 overflow-hidden rounded cursor-pointer group">
                      <img
                        src={previewUrl}
                        alt="Attachment"
                        className="absolute inset-0 w-full h-full object-cover hover:opacity-90 transition-opacity"
                        onClick={() => ticketsService.openFile(message.attachment)}
                      />
                      {/* Overlay with file info on hover */}
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all duration-200">
                        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              ticketsService.openFile(message.attachment);
                            }}
                            className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                            title="Open in new tab"
                          >
                            <Maximize size={16} />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              ticketsService.downloadFile(message.attachment);
                            }}
                            className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                            title="Download"
                          >
                            <Download size={16} />
                          </button>
                        </div>
                        {/* File name on hover */}
                        <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-50 text-white text-xs truncate opacity-0 group-hover:opacity-100 transition-opacity">
                          {message.attachment.split('/').pop()}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex items-center p-3 bg-black bg-opacity-5 rounded">
                  <Paperclip size={16} className="mr-2" />
                  <div className="flex-1 min-w-0">
                    <div className="text-sm font-medium truncate">
                      {message.attachment.split('/').pop()}
                    </div>
                  </div>
                  <button
                    onClick={() => ticketsService.downloadFile(message.attachment)}
                    className="ml-2 p-2 rounded hover:bg-black hover:bg-opacity-10"
                    title="Download"
                  >
                    <Download size={16} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
};
  
   
   
  
  
    return (
      <div className="flex flex-col h-full bg-white">
        {/* Header */}
        <div className="bg-gray-50 p-4 border-b">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold">
                Ticket #{ticket.id} - {ticket.subject}
              </h3>
              <p className="text-sm text-gray-600">
                Status: {ticket.status} • Member: {ticket.member.username}
              </p>
            </div>
          </div>
        </div>
  
        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <>
              {messages.map((message) => (
                <MessageBubble key={message.id} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </>
          )}
        </div>
  
        {/* Input Area */}
        <div className="border-t p-4">
          <div className="flex items-end space-x-4">
            <div className="flex-1">
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                className="w-full border rounded-lg p-3 min-h-[100px] resize-none"
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
              />
              
              {(attachment || attachmentPreview) && (
                <div className="mt-2 p-2 bg-gray-50 rounded-lg">
                  {attachmentPreview ? (
                    <div className="relative inline-block">
                      <img
                        src={attachmentPreview}
                        alt="Preview"
                        className="max-h-32 rounded"
                      />
                      <button
                        onClick={clearAttachment}
                        className="absolute -top-2 -right-2 p-1 bg-red-500 rounded-full text-white"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between p-2">
                      <div className="flex items-center space-x-2">
                        <Paperclip size={16} />
                        <span className="text-sm truncate">{attachment.name}</span>
                        <span className="text-xs text-gray-500">
                          ({formatFileSize(attachment.size)})
                        </span>
                      </div>
                      <button
                        onClick={clearAttachment}
                        className="text-red-500 hover:text-red-700"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            
            <div className="flex space-x-2">
              <button
                onClick={() => fileInputRef.current?.click()}
                className="p-3 rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-600"
              >
                <Image size={20} />
              </button>
              <button
                onClick={handleSend}
                disabled={!newMessage.trim() && !attachment}
                className="p-3 rounded-lg bg-blue-500 hover:bg-blue-600 text-white disabled:opacity-50"
              >
                <Send size={20} />
              </button>
            </div>
            
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileSelect}
              accept="image/*,.pdf,.doc,.docx"
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default TicketChat;