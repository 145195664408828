import React from 'react';
import { Plus } from 'lucide-react';

function PayPlanList({ payPlans, onSelect, selectedId, onCreateNew }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-lg font-medium text-gray-700 mb-4">Pay Plans</h2>
      <ul className="space-y-2 mb-4">
        {payPlans.map((plan) => (
          <li
            key={plan.id}
            className={`cursor-pointer p-2 rounded-md transition-colors duration-200 ${
              selectedId === plan.id ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
            }`}
            onClick={() => onSelect(plan)}
          >
            {plan.name}
          </li>
        ))}
      </ul>
      <button
        onClick={onCreateNew}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
      >
        <Plus className="h-5 w-5" />
       
      </button>
    </div>
  );
}

export default PayPlanList;